<template>
  <div class="CreatorStatisticsSubs">
    <AdminStatisticsFilter :statistics="statistics" :title="$locale['subscriptions_statistics']">
      <LargeButton
        :icon="`users`"
        :label="$locale['total_subscriptions']"
        :rightDesc="`<strong skeleton>${number_format(statistics.totalItems)}</strong>`"
        prevent
      />
      <LargeButton
        :icon="`filter-list`"
        :label="$locale['filters']"
        :rightIcon="false"
        :rightDesc="`<span skeleton>${number_format(numFilters)}</span>`"
        @click="
          view({
            title: $locale['filters'],
            component: () => import('@/components/widgets/InfoFilter'),
            closeOutside: true,
            class: 'menu bottom-mbl',
            animation: 'bottomIn',
          })
        "
      />
    </AdminStatisticsFilter>

    <div :class="`statistics-block __general ${$loading ? '__loading' : '__loaded'}`">
      <div class="ReportInfo">
        <LargeButton
          v-for="(subscription, index) in items"
          :key="index"
          :label="subscription.user.name"
          :desc="`@${subscription.creator.user} ${date_locale(subscription.date)}`"
          :UserAvatar="subscription.user"
          :iconSize="`48px`"
          :rightDesc="`<strong skeleton nowrap>$ ${number_format(subscription.price, 2)}</strong><small skeleton>${$locale['usd']}</small>`"
          @click="
            view({
              title: $locale['subscriber'],
              component: () => import('@/components/creator/MySubscriber.vue'),
              class: `menu bottom`,
              closeOutside: true,
              animation: 'bottomIn',
              user: subscription.user,
              subscription: subscription,
            })
          "
        />
        <LoadMore :morepage="statistics.hasMore" @more="getStatistics({ page: statistics.page + 1 })" />
        <CardMessage v-if="!$loading && !statistics.totalItems" :message="$locale['no_subscriptions_message']" />
      </div>
    </div>
  </div>
</template>

<script>
import FeedList from "@/components/feed/feed.list";
import UserFeed from "@/components/user/UserFeed";
export default {
  mixins: [UserFeed, FeedList],
  components: {
    AdminStatisticsFilter: () => import("../admin/AdminStatisticsFilter.vue"),
  },
  data: function() {
    return {
      endpoint: `${process.env.VUE_APP_API_HOST}/creator/statistics-subscriptions`,
      storeId: "my_transactions",
      items: [],
      statistics: { totalItems: 0, page: 1 },
      limit: 20,
      axios: {
        headers: {
          "use-form": "false",
        },
      },
    };
  },
};
</script>

<style lang="scss">
.CreatorStatisticsSubs {
  .CreatorStatisticsSubsCard .LargeButtonDesc {
    opacity: 1;
  }

  .CreatorStatisticsSubsCard [date] {
    opacity: 0.5;
    margin: $mpadding/4 0 0 0;
  }

  .CreatorStatisticsSubsCard [warn] {
    background-color: var(--warn-color);
    color: #fff;
    margin: $mpadding/2 0 0 0;
    display: inline-block;
  }

  .CreatorStatisticsSubsCard[type="reload"] [mount] {
    color: var(--color-tips);
  }
}
</style>
